




























































import { Vue, Component } from "vue-property-decorator";

// import axios from 'axios'
// import states from '@/assets/dictionaries/state-list.ts';
declare module "@/assets/dictionaries/state-list";

@Component
export default class AdminHub extends Vue {
    expandOnHover = true;
    mobile = "reduce";
    reduce = true;
    menuItems = [
        {
            routeName: "AdminPanel",
            title: "Dashboard",
            icon: "tachometer-alt"
        },
        {
            routeName: "AdminSession",
            title: "Sessions",
            icon: "clipboard-list"
        },
        {
            routeName: "AdminEvaluations",
            title: "Evaluations",
            icon: "star-half-alt"
        },
        {
            routeName: "Home",
            title: "Back to Site",
            icon: "home"
        }
    ];

    routeTo(routeString: string) {
        this.$router.push({
            name: routeString
        });
    }
}
